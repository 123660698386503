<template>
  <div id="app" ref="home">
    <div v-html="statistics"></div>
    <!-- {{statistics}} -->
    <div class="instance" @click="addImage" @touchstart="touchStart" @touchend="touchEnd">
      <img
        style="width: 100%; height: 100%"
        src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/driving_license_example_20210311101508.png"
        alt
      />
      <van-image-preview
        v-model="isShowPreview"
        v-if="isShowPreview"
        :images="previewImages"
        :loop="false"
        @close="onClose()"
      ></van-image-preview>
      <!-- 长按 保存手机 事件 -->
      <van-action-sheet v-model="touchPressHidden" :actions="actions" @select="touchPress" />
    </div>
    <!-- ocr上传 -->
    <div class="ocrIdentify">
      <div class="ocrIdentifyTop">
        <div class="title">智能识别</div>
        <!-- <div @click="helpClick" class="help">帮助</div>
        <van-icon @click="helpClick" name="question-o" size="14px" class="iconC" bindtap="explain" />-->
      </div>
      <div class="upload_title">
        上传以下
        <span style="color:#1271FF">任意 1 张</span> 证件，快速完成填写
      </div>
      <div class="ocrImageView">
        <ul class="ocr_ul">
          <li class="ocr_li" :class="{'ocr_active':ocr_actives}" @click="changeType(1)">行驶证</li>
          <li
            class="ocr_li"
            style="margin:12px 0"
            :class="{'ocr_active':ocr_key}"
            @click="changeType(2)"
          >登记证</li>
          <li
            class="ocr_li"
            style="margin:12px 0"
            :class="{'ocr_active':ocr_online}"
            @click="changeType(3)"
          >合格证</li>
          <li class="ocr_li" :class="{'ocr_active':ocr_qualified}" @click="changeType(4)">发票</li>
        </ul>
        <div class>
          <van-uploader
            v-if="!valuationInfo.ossUrl"
            :after-read="touchPhoto"
            name="drivingLicenseImage"
          >
            <img
              v-if="ocr_actives &&!valuationInfo.ossUrl"
              src="@/assets/ocr.png"
              class="images"
              alt
            />
            <img v-if="ocr_key &&!valuationInfo.ossUrl" src="@/assets/ocr2.png" class="images" alt />
            <img
              v-if="ocr_online &&!valuationInfo.ossUrl"
              src="@/assets/ocr3.png"
              class="images"
              alt
            />
            <img
              v-if="ocr_qualified &&!valuationInfo.ossUrl"
              src="@/assets/ocr4.png"
              class="images"
              alt
            />
          </van-uploader>
          <!--  -->
          <div class="ocr_img_show" v-if="valuationInfo.ossUrl">
            <img
              @click="onImageClick(valuationInfo.ossUrl)"
              :src="valuationInfo.ossUrl"
              alt
              class="images"
            />
            <span @click="remove_ocr" class="clear_ocr">清除</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 提示 -->
    <van-dialog
      :confirmButtonText="promptTitle"
      v-model="promptHidden"
      title="提示"
      @confirm="isActive = false"
      confirmButtonColor="#507496"
    >
      <div class="promptView">
        <div class="promptTextSize">车型及识别代号很重要哦！</div>
        <div class="promptTextSize mar10">请再核验一次吧！</div>
      </div>
    </van-dialog>
    <div class="carInfoView">
      <div class="ocrIdentifyTop">
        <div class="title" style="padding-left: 10px;">车辆信息</div>
      </div>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText">车牌号码</div>
        <input
          v-model.trim="valuationInfo.PlateNo"
          type="text"
          :readOnly="readOnly"
          placeholder="赣C90***"
        />
      </div>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText">车辆类型</div>
        <input
          type="text"
          v-model.trim="valuationInfo.VehicleType"
          :readOnly="readOnly"
          placeholder="重型自卸***"
        />
      </div>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText">车辆品牌</div>
        <input type="text" v-model="valuationInfo.brand" :readOnly="readOnly" placeholder="**牌" />
      </div>
      <div class="carInfoViewLi" :class="{'carInfoViewLis':vehicleInfoHidden}">
        <div class="carInfoViewLiss">
          <div class="carInfoViewLiText" :class="[isActive ? 'carInfoViewLiActive' : '']">车辆型号</div>
          <!-- :placeholder-class="[errorClass == 0 ? 'errorClass' : '']" -->
          <input
            type="text"
            v-model.trim="valuationInfo.vehicleModel"
            :readOnly="readOnly"
            @blur="vehicleModelBlur"
            placeholder="DFH****"
          />
          <van-icon
            style="margin-left: 10px"
            @click="onClickVehicleModel"
            name="arrow-down"
            size="14px"
          />
        </div>
        <div v-if="vehicleInfoHidden" @click="customizationActive=true" class="newVals">
          <img
            src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/customizationerror.png"
            alt
          />
          <div>行驶证的型号可能未打印全</div>
          <van-icon style="margin-left: 24px" name="arrow-down" size="14px" />
        </div>
      </div>
      <!-- 车辆型号的弹出层 -->
      <van-popup v-model="vehicleModelHidden" round position="bottom" :style="{ height: '40%' }">
        <van-picker
          title="车辆型号"
          show-toolbar
          :columns="valuationInfo.vehicleModelList"
          @confirm="onConfirmVehicleModel"
          @cancel="onCancelVehicleModel"
        />
      </van-popup>
      <van-popup v-model="customizationActive" round position="center" :style="{ width: '266rpx' }">
        <div class="customizationPopup">
          <div class="jiefangButton" @click="customizationActive=false">我知道了</div>
        </div>
      </van-popup>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText" :class="[isActive ? 'carInfoViewLiActive' : '']">车辆识别代号</div>
        <input
          type="text"
          v-model.trim="valuationInfo.Vin"
          :readOnly="readOnly"
          placeholder="LGAX4*******"
          :class="{'inp_active':vinActive}"
          @blur="checkVehicleParams"
        />
        <img
          v-if="vinActive"
          @click="vinShow=true"
          class="vinActiveImage"
          src="@/assets/title.png"
          alt
        />
      </div>
      <van-popup v-model="vinShow" round>
        <div class="vinShow">
          <div class="title">提示</div>
          <div v-for="(item,index) in vinInfoList" :key="index" class="conent">{{item}}</div>
          <div class="btn" @click="vinShow=false">知道了</div>
        </div>
      </van-popup>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText">发动机/电机号码</div>
        <input
          type="text"
          v-model.trim="valuationInfo.EngineNo"
          :readOnly="readOnly"
          placeholder="JA4S***"
        />
      </div>
      <div class="carInfoViewLi" @click="RegisterDateClick">
        <div class="carInfoViewLiText">注册日期</div>
        <input type="text" v-model="valuationInfo.RegisterDate" readonly placeholder="请选择注册日期" />
        <!-- <div class="zixiSecondFlex" v-if="timeHidden">日期错误</div> -->

        <van-icon style="margin-left: 10px" name="arrow-down" size="14px" />
      </div>
      <van-popup position="bottom" :style="{ height: '38%' }" round v-model="RegisterDateHidden">
        <van-datetime-picker
          v-model="RegisterDateH5"
          type="date"
          title="注册日期"
          @cancel="RegisterCancel"
          @confirm="RegisterConfirm"
          :min-date="minDate"
          :max-date="maxDate"
        />
      </van-popup>
      <!-- <div class="carInfoViewLi" @click="IssueDateClick">
        <div class="carInfoViewLiText">发证日期</div>
        <input type="text" v-model="valuationInfo.IssueDate" readonly placeholder="请选择发证日期" />
        <van-icon style="margin-left: 10px" name="arrow-down" size="14px" />
      </div>-->
      <van-popup position="bottom" :style="{ height: '38%' }" round v-model="IssueDateHidden">
        <van-datetime-picker
          v-model="IssueDateDateH5"
          type="date"
          title="发证日期"
          @cancel="IssueCancel"
          @confirm="IssueConfirm"
          :min-date="minDate"
          :max-date="maxDate"
        />
      </van-popup>
      <div class="carInfoViewLi" @click="UseCharacterDateClick">
        <div class="carInfoViewLiText">使用性质</div>
        <input type="text" v-model="valuationInfo.UseCharacter" readonly placeholder="请选择使用性质" />
        <van-icon style="margin-left: 10px" name="arrow-down" size="14px" />
      </div>
      <van-popup position="bottom" :style="{ height: '38%' }" round v-model="UseCharacterHiddens">
        <van-picker
          title="使用性质"
          show-toolbar
          :columns="UseCharacterList"
          @confirm="onConfirmUseCharacter"
          @cancel="UseCharacterHiddens=false"
        />
      </van-popup>
    </div>
    <!-- 基础信息部分 -->
    <div class="basisInfoView">
      <div class="ocrIdentifyTop">
        <div class="title">基础信息</div>
      </div>

      <div class="basisInfoViewLi">
        <img class="iconImages" src="@/assets/fangzi.png.png" alt />
        <div class="basisInfoViewLiText">车辆注册地址</div>
        <input
          type="text"
          readonly
          v-model="valuationInfo.locale"
          placeholder="请选择车辆注册地址"
          @click="changeRegin"
        />
        <van-icon style="margin-left: 10px" name="arrow-down" size="14px" />
      </div>
      <!-- 省市区 -->
      <van-popup position="bottom" :style="{ height: '60%' }" round v-model="reginHidden">
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="options"
          @close="reginHidden = false"
          @finish="onFinish"
          :field-names="fieldNames"
          active-color="#1271FF"
        />
      </van-popup>
      <div class="basisInfoViewLi" style="margin-top: 6px">
        <img class="iconImages" src="@/assets/yushua.png.png" alt />
        <div class="basisInfoViewLiText">行驶里程(km)</div>
        <!-- :readOnly="readOnly" -->
        <input type="text" placeholder="请输入行驶里程" v-model.trim="mileage" />
      </div>
    </div>
    <!-- 车辆配置选项  -->
    <div class="configurationView" v-if="engineNameColumns.length||optionsList.length">
      <div class="ocrIdentifyTop" style="padding:0;">
        <div class="title">配置选项</div>
      </div>
      <div class="configureCen">
        <div class="picker" v-if="engineNameColumns.length" @click="engineNameClick">
          <div class="pickerLi">
            <div v-if="!isNewEnergy">发动机型号</div>
            <div v-if="isNewEnergy">电机型号</div>
            <div
              class="pickerText"
              v-if="engineBrand&&engineModel"
            >{{ engineBrand }} {{ engineModel }}</div>
            <div class="pickerText" style="color: #999999" v-else>
              <div v-if="!isNewEnergy">请选择发动机型号</div>
              <div v-if="isNewEnergy">请选择电机型号</div>
            </div>
            <van-icon name="arrow-down" size="14px" />
          </div>
        </div>
        <!-- 发动机型号弹出层 -->
        <van-popup position="bottom" :style="{ height: '38%' }" round v-model="engineNameHidden">
          <!-- :columns="valuationInfo.engine" -->
          <van-picker
            show-toolbar
            @cancel="engineNameHidden = false"
            @confirm="engineNameConfirm"
            :columns="engineNameColumns"
            :values="engineNameValue"
          />
        </van-popup>
        <div
          class="picker"
          v-if="optionsList.length"
          v-for="(item,index) in optionsList"
          :key="index"
        >
          <div class="pickerLi">
            <div>{{item.name}}</div>
            <input v-model.trim="item.value" style="width:200px" type="text" placeholder="请输入或选择" />
            <van-icon @click="item.select=true" name="arrow-down" size="14px" />
          </div>
          <van-popup position="bottom" :style="{ height: '38%' }" round v-model="item.select">
            <van-picker
              :title="item.name"
              show-toolbar
              :columns="item.option"
              @confirm="drivingTypConfirm"
              @cancel="drivingTypeHidden = false"
            />
          </van-popup>
        </div>
      </div>
    </div>
    <!-- 车辆图片 -->
    <div class="carImageView">
      <div class="ocrIdentifyTop">
        <div class="title">车辆照片</div>
      </div>
      <div class="carImageViewFlex">
        <div
          class="carImageViewFlexLi"
          v-for="(item, index) in ImgList"
          v-show="index < imgNumber"
          :key="item.name"
        >
          <van-uploader :after-read="additionalImage" :name="item.name">
            <div class="image">
              <!-- 顶部加号 -->
              <img
                v-if="!item.imgUrl"
                src="@/assets/adds.png"
                style="width: 24px; height: 24px"
                alt
              />
              <!-- 删除图片 -->
              <img
                v-if="item.imgUrl"
                class="remoteImage"
                src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/error_symbol_20210527.png"
                alt
                @click="imageDelete(item, index)"
              />
              <!-- 用户上传图片 -->
              <img
                :key="item.name"
                v-if="item.imgUrl"
                style="width: 100%; height: 100%"
                :src="item.imgUrl"
                alt
              />
            </div>
          </van-uploader>
          <div class="text">{{ item.cname }}</div>
        </div>
      </div>
      <button @click="addCarImageClick" v-if="imgNumber < 20" class="carImageBtn">添加车辆照片</button>
    </div>
    <!-- 下一步按钮 -->
    <button class="nextStep" @click="nextStepClick">下一步</button>
    <!-- 进口车型 -->
    <van-dialog
      confirm-button-color="#507496"
      v-model="importedModelsShow"
      @confirm="windowOpen"
      confirm-button-text="立即填写"
      title="提示"
      :show-cancel-button="false"
    >
      <div style="color: #999999;font-size:16px;text-align:center;line-height:50px">进口车型，需更多信息确认</div>
    </van-dialog>
    <!-- 弹出信息 -->
    <van-popup
      @close="closeMore"
      round
      v-model="moreShow"
      position="bottom"
      :style="{ height: '52%' }"
    >
      <div class="g-content">
        <div class="g-title">更多信息</div>
        <ul class="g-brand">
          <li class="g-name">品牌</li>
          <li>{{valuationInfo.brand}}</li>
        </ul>
        <!-- 车系 -->
        <ul class="g-car g-brand" @click="seriesShow = true">
          <li class="g-items">
            <div class="g-name">车系</div>
            <div class="g-select">
              <div v-if="!series">请选择</div>
              <div v-if="series">{{series}}</div>
              <div>
                <van-icon name="arrow" />
              </div>
            </div>
          </li>
        </ul>
        <!-- 马力 -->
        <ul class="g-car g-brand" @click="getHorse">
          <li class="g-items">
            <div class="g-name" v-if="horse">马力</div>
            <div class="g-name" v-if="!horse" style="color:#cccccc">马力</div>
            <div class="g-select">
              <div v-if="!horse" style="color:#cccccc">请选择</div>
              <div v-if="horse">{{horse}}</div>
              <div>
                <van-icon name="arrow" />
              </div>
            </div>
          </li>
        </ul>
        <!-- 驱动 -->
        <ul class="g-car g-brand" @click="getDrive">
          <li class="g-items">
            <div class="g-name" v-if="drive">驱动</div>
            <div class="g-name" v-if="!drive" style="color:#cccccc">驱动</div>
            <div class="g-select">
              <div v-if="!drive" style="color:#cccccc">请选择</div>
              <div v-if="drive">{{drive}}</div>
              <div>
                <van-icon name="arrow" />
              </div>
            </div>
          </li>
        </ul>
        <!-- 排放 -->
        <ul class="g-car g-brand" @click="dischargeShow=true">
          <li class="g-items">
            <div class="g-name">排放</div>
            <div class="g-select">
              <div v-if="!discharge">请选择</div>
              <div v-if="discharge">{{discharge}}</div>
              <div>
                <van-icon name="arrow" />
              </div>
            </div>
          </li>
        </ul>
        <div class="g-tips">提醒：进口车辆，车辆型号请以反馈的数据为准。</div>
        <div class="g-btn" @click="submit">确定</div>
      </div>
    </van-popup>
    <!-- 车系 -->
    <van-popup round v-model="seriesShow" position="bottom" :style="{ height: '44%' }">
      <van-picker
        round
        title="车系"
        show-toolbar
        :columns="seriesColumns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    <!-- 马力 -->
    <van-popup round v-model="horseShow" position="bottom" :style="{ height: '44%' }">
      <van-picker
        round
        title="马力"
        show-toolbar
        :columns="horseColumns"
        @confirm="onConfirmHorse"
        @cancel="onCancel"
      />
    </van-popup>
    <!-- 驱动 -->
    <van-popup round v-model="driveShow" position="bottom" :style="{ height: '44%' }">
      <van-picker
        round
        title="驱动"
        show-toolbar
        :columns="driveColumns"
        @confirm="onConfirmDrive"
        @cancel="onCancel"
      />
    </van-popup>
    <!-- 排放 -->
    <van-popup round v-model="dischargeShow" position="bottom" :style="{ height: '44%' }">
      <van-picker
        round
        title="排放"
        show-toolbar
        :columns="dischargeColumns"
        @confirm="onConfirmDischarge"
        @cancel="onCancel"
      />
    </van-popup>
    <!-- 此车是否为预挂车? -->
    <van-popup v-model="trailerShow" round position="center" bind:close="onClose">
      <div class="trailer">
        <div class="title">温馨提示</div>
        <div class="content">此车是否为预挂车(准新车)？</div>
        <div class="center">默认为否</div>
        <div class="btn">
          <div class="left" @click="onClickTrailer(1)">是</div>
          <div class="right" @click="onClickTrailer(2)">否</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script type="text/javascript" src='https://c.cnzz.com/core.php?web_id=1281102051&t=z' charset="utf-8">
</script>
<script>
import { Toast } from "vant";
// import { Popup } from "vant";
// import { Icon } from 'vant';
import {
  ocrConsumer,
  getVehicleOption,
  upPicture,
  getImportedVehicleOptions,
  uploadImage,
  checkTrailer, //挂车验证
  checkVehiclePreSale, //预挂车
  checkVehicleParam, //vin码验证
  checkVehicleImported, //进口车验证
} from "@/utils/http.js"; //路径注意
import { formatDateYMM } from "@/utils/time.js";
import { city } from "@/utils/pca-code.js";
import { debounce } from "@/utils/common.js";
import { Dialog, ImagePreview } from "vant";
export default {
  // 组件名称
  name: "demo",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      isNewEnergy: false,
      vinActive: false,
      vinShow: false,
      vinInfoList: [123123123123],
      optionsList: [],
      lengths: "", //车长
      customizationActive: false,
      trailerShow: false,
      vehicleInfoHidden: false,
      ocr_actives: true, //行驶证
      ocr_key: false, //登记证
      ocr_online: false, //发票
      ocr_qualified: false, //合格证
      statistics: "",
      // 进口车型
      seriesShow: false, //车系弹窗
      series: "", //车系数据
      importedModelsShow: false, //进口车型弹窗
      moreShow: false, //填写信息
      seriesColumns: ["杭州", "宁波", "温州"],
      horseShow: false,
      horseColumns: ["杭州", "宁波"],
      horse: "",
      driveShow: false,
      drive: "",
      driveColumns: ["杭州", "宁波", "温州"],
      dischargeShow: false,
      discharge: "",
      dischargeColumns: ["国四", "国五", "国六"],
      // 进口车型
      promptHidden: false, // 三秒提示框显示隐藏
      promptTitle: "确认(3)", //三秒提示框文案
      promptTimer: null, //三秒提示框的倒计时
      isActive: false, //车辆类型/车辆识别代码 标红，三秒后为 false
      previewImages: [
        "https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/driving_license_example_img_20210311163152.png",
      ],
      isShowPreview: false, //显示隐藏
      touchPressHidden: false, //保存手机的显示隐藏
      actions: [{ name: "保存图片" }, { name: "取消" }],
      timeOutEvent: null,
      // ocr部分参数
      readOnly: false, //input禁止输入
      RegisterDateHidden: false, //注册日期显示隐藏
      IssueDateHidden: false, //发证日期显示隐藏
      UseCharacterHiddens: false,
      UseCharacterList: ["货运", "客运", "营运", "非营运", "出租", "营转非"],
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2100, 10, 1),
      RegisterDateH5: new Date(), //注册日期显示
      IssueDateDateH5: new Date(), //发证日期显示
      errorClass: 0, //错误展示标红
      timeHidden: false, // 注册日期红
      // 省市区
      reginHidden: false, //省市区的显示隐藏
      cascaderValue: "",
      options: [],
      fieldValue: "",
      fieldNames: {
        text: "label",
        value: "value",
        children: "children",
      },
      engineNameValue: {
        text: "engineName",
        children: "engineModels",
      },
      // 行驶里程
      mileageHidden: false,
      mileage: "",
      // 发动机
      engineNameHidden: false, // 发动机型号显示隐藏
      engineNameColumns: [], //发动机配置
      engineBrand: "", //发动机品牌
      engineModel: "", // 发动机型号
      // 驱动形式
      drivingTypeHidden: false, //驱动形式显示隐藏
      drivingTypeColumns: [],
      drivingTypeSelect: "", //选择驱动
      // 速比
      speedRatioHidden: false, //速比显示隐藏
      speedRatioColumns: [],
      speedRatioSelect: "", //选择速比
      // 变速形式
      gearboxHidden: false, // 变速形式的显示隐藏
      gearboxColumns: [],
      gearboxSelect: "", //选择变速器
      // 上传图片
      imgNumber: 0, //默认0个
      ImgList: [], // 进入页面获取的图片
      // 数据
      valuationInfo: {},
      // 模拟数据
      //车辆型号的展示隐藏
      vehicleModelHidden: false,
      pic: {
        success: true,
        code: 200,
        message: "success",
        data: {
          charts: [
            { name: "urlPic0", cname: "正前" },
            { name: "urlPic1", cname: "左前侧45度" },
            { name: "urlPic2", cname: "右前侧45度" },
            { name: "urlPic3", cname: "左侧" },
            { name: "urlPic4", cname: "右侧" },
            { name: "urlPic5", cname: "正后" },
            { name: "urlPic6", cname: "仪表盘(点火状态)" },
            { name: "urlPic7", cname: "中控" },
            { name: "urlPic8", cname: "排挡杆" },
            { name: "urlPic9", cname: "前轮" },
            { name: "urlPic10", cname: "中轮" },
            { name: "urlPic11", cname: "后轮" },
            { name: "urlPic12", cname: "发动机" },
            { name: "urlPic13", cname: "变速器" },
            { name: "urlPic14", cname: "驱动桥" },
            { name: "urlPic15", cname: "车辆铭牌" },
            { name: "urlPic16", cname: "登记证书" },
            { name: "urlPic17", cname: "其他" },
          ],
        },
      },
      falseData: {
        appKey: "1",
        sign: "1",
        randomStr: "1",
        expireTime: "1",
        token: 1,
      },
    };
  },
  methods: {
    // 点击图片
    onImageClick(url) {
      let arr = [url];
      ImagePreview({
        images: arr,
        startPosition: 0,
      });
    },
    // 更改ocr类型
    changeType(e) {
      this.valuationInfo.ossUrl = "";
      switch (e) {
        case 1:
          this.ocr_actives = true; //行驶证
          this.ocr_key = false; //登记证
          this.ocr_online = false; //发票
          this.ocr_qualified = false; //合格证
          break;
        case 2:
          this.ocr_actives = false; //行驶证
          this.ocr_key = true; //登记证
          this.ocr_online = false; //发票
          this.ocr_qualified = false; //合格证
          break;
        case 3:
          this.ocr_actives = false; //行驶证
          this.ocr_key = false; //登记证
          this.ocr_online = true; //发票
          this.ocr_qualified = false; //合格证
          break;
        case 4:
          this.ocr_actives = false; //行驶证
          this.ocr_key = false; //登记证
          this.ocr_online = false; //发票
          this.ocr_qualified = true; //合格证
          break;

        default:
          break;
      }
    },
    remove_ocr() {
      this.valuationInfo.ossUrl = "";
      console.log(this.valuationInfo.ossUrl);
    },
    closeMore() {
      let a = 3;
      let that = this;
      this.promptHidden = true;
      this.isActive = true;
      // 定时器 三秒后关闭
      // console.log("111");
      this.promptTimer = setInterval(function () {
        a--;
        // console.log(a);
        if (a === -1) {
          clearInterval(that.promptTimer);
          that.promptHidden = false;
          that.isActive = false;
          return;
        }
        that.promptTitle = `确认(${a})`;
      }, 1000);
    },
    async submit() {
      let list = {
        field: "model",
        brand: this.valuationInfo.brand,
        series: this.series,
        horsepower: this.horse,
        emissionStd: this.discharge,
        driveType: this.drive,
        appKey: this.falseData.appKey,
        sign: this.falseData.sign,
        randomStr: this.falseData.randomStr,
        expireTime: this.falseData.expireTime,
        token: this.falseData.token || "",
      };
      const { data: res } = await getImportedVehicleOptions(list);
      // if (res.data.result.failureReason) {
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }
      // }

      // console.log(res)
      // console.log(res.data.result.content[0])
      this.valuationInfo.vehicleModel = res.data.result.content[0];
      this.valuationInfo.vehicleModelList = res.data.result.content;
      this.moreShow = false;
      // this.closeMore()
      let a = 3;
      let that = this;
      this.promptHidden = true;
      this.isActive = true;
      // 定时器 三秒后关闭
      // console.log("111");
      this.promptTimer = setInterval(async function () {
        a--;
        // console.log(that.promptTimer, "========");
        if (a == -1) {
          // console.log(a, "----------");
          await clearInterval(that.promptTimer);
          that.promptHidden = false;
          that.isActive = false;
          return;
        } else {
          if (a == -2) {
            // clearInterval(that.promptTimer);
            a = 0;
          }
          // console.log(a, "============");
        }
        that.promptTitle = `确认(${a})`;
      }, 1000);
    },
    getHorse() {
      if (!this.series) {
        Toast({
          message: "请选择上一级",
          position: "top",
        });
        return;
      }
      this.horseShow = true;
    },
    getDrive() {
      if (!this.horse) {
        Toast({
          message: "请选择上一级",
          position: "top",
        });
        return;
      }
      this.driveShow = true;
    },
    onConfirmDischarge(e) {
      this.discharge = e;
      this.dischargeShow = false;
    },
    // 确认
    async onConfirm(e) {
      this.series = e;
      this.horse = "";
      this.drive = "";
      this.seriesShow = false;
      let list = {
        brand: this.valuationInfo.brand,
        field: "horsepower",
        series: this.series,
        emissionStd: this.drive,
        appKey: this.falseData.appKey,
        sign: this.falseData.sign,
        randomStr: this.falseData.randomStr,
        expireTime: this.falseData.expireTime,
        token: this.falseData.token || "",
      };
      const { data: res } = await getImportedVehicleOptions(list);
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }

      // console.log(res);
      this.horseColumns = res.data.result.content;
    },
    async onConfirmHorse(e) {
      this.horse = e;
      this.drive = "";
      this.horseShow = false;
      let list = {
        brand: this.valuationInfo.brand,
        field: "driveType",
        series: this.series,
        driveType: this.drive,
        appKey: this.falseData.appKey,
        sign: this.falseData.sign,
        randomStr: this.falseData.randomStr,
        expireTime: this.falseData.expireTime,
        token: this.falseData.token || "",
      };
      const { data: res } = await getImportedVehicleOptions(list);
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }

      // console.log(res);
      this.driveColumns = res.data.result.content;
    },
    onConfirmDrive(e) {
      this.drive = e;
      this.driveShow = false;
    },
    onCancel() {
      this.seriesShow = false;
      this.horseShow = false;
      this.driveShow = false;
      this.dischargeShow = false;
    },
    async windowOpen() {
      this.moreShow = true;
      this.horse = "";
      this.drive = "";
      this.series = "";
      this.discharge = "";
      let list = {
        brand: this.valuationInfo.brand,
        field: "series",
        appKey: this.falseData.appKey,
        sign: this.falseData.sign,
        randomStr: this.falseData.randomStr,
        expireTime: this.falseData.expireTime,
        token: this.falseData.token || "",
      };
      const { data: res } = await getImportedVehicleOptions(list);
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }

      // console.log(res);
      this.seriesColumns = res.data.result.content;
    },
    // 点击车辆型号
    onClickVehicleModel() {
      if (!this.valuationInfo.vehicleModelList) return;
      this.vehicleModelHidden = true;
    },
    // 关闭
    onCancelVehicleModel() {
      this.vehicleModelHidden = false;
    },
    // 车辆型号的确认按钮
    onConfirmVehicleModel(event) {
      // console.log(event);
      this.valuationInfo.vehicleModel = event;
      this.vehicleModelHidden = false;
      this.vehicleModelBlur();
    },
    onConfirmUseCharacter(e) {
      this.valuationInfo.UseCharacter = e;
      this.UseCharacterHiddens = false;
    },
    addImage() {
      this.isShowPreview = true;
    },
    // 点击
    touchStart(event) {
      let _this = this;
      clearTimeout(_this.timeOutEvent);
      _this.timeOutEvent = setTimeout(function () {
        _this.timeOutEvent = 0;
        _this.touchPressHidden = true;
      }, 600);
    },
    // 松手
    touchEnd(event) {
      let _this = this;
      clearTimeout(_this.timeOutEvent);
    },
    // 长按 事件
    touchPress(event) {
      let _this = this;
      if (event.name == "取消") {
        this.touchPressHidden = false;
      } else {
        // console.log(window);
        // console.log(window.plus);
        if (!window.plus) return;
        plus.gallery.save(
          this.previewImages[0],
          function () {
            Toast("保存成功!");
            this.touchPressHidden = false;
          },
          function () {
            Toast("保存失败，请重试!");
          }
        );
      }
    },
    // 点击帮助
    helpClick() {
      this.$router.push("/quickValuation/help");
    },
    tips(data) {
      if (data) {
        if (data == 1000) {
          Dialog.alert({
            title: "提示信息",
            message: "验证失败,请返回上级页面重新验证",
          }).then(() => {});
          return false;
        }
        if (data == 500) {
          Dialog.alert({
            title: "提示信息",
            message: "ocr识别失败",
          }).then(() => {});
          return false;
        }
        if (data == 1001) {
          Dialog.alert({
            title: "提示信息",
            message: "appKey错误",
          }).then(() => {});
          return false;
        }
        if (data == 1002) {
          Dialog.alert({
            title: "提示信息",
            message: "暂无权限",
          }).then(() => {});
          return false;
        }
        if (data == 1003) {
          Dialog.alert({
            title: "提示信息",
            message: "余额不足",
          }).then(() => {});
          return false;
        }
        if (data == 1004) {
          Dialog.alert({
            title: "提示信息",
            message: "签名已过期",
          }).then(() => {});
          return false;
        }
      }
      return true;
    },
    async checkTrailers() {
      let pas = {
        appKey: this.falseData.appKey,
        sign: this.falseData.sign,
        randomStr: this.falseData.randomStr,
        expireTime: this.falseData.expireTime,
        token: this.falseData.token || "",
        vehicleModel: this.valuationInfo.vehicleModel,
      };
      const { data: r } = await checkTrailer(pas);
      this.valuationInfo.isTrailer = r.data.isTrailer;
    },
    // 验证Vin码
    async checkVehicleParams() {
      if (!this.valuationInfo.Vin) return;
      if (!this.valuationInfo.vehicleModel) return;
      let params = {
        appKey: this.falseData.appKey,
        sign: this.falseData.sign,
        randomStr: this.falseData.randomStr,
        expireTime: this.falseData.expireTime,
        token: this.falseData.token || "",
        vehicleModel: this.valuationInfo.vehicleModel,
        vin: this.valuationInfo.Vin,
      };
      const { data: res } = await checkVehicleParam(params);
      if (!res.data.result.content) return;
      let result = res.data.result.content;
      if (result.vehicleModel.modify) {
        this.valuationInfo.vehicleModel = result.vehicleModel.newValue;
      }
      if (result.vin.modify) {
        this.valuationInfo.Vin = result.vin.newValue;
      }
      if (result.vin.pass) {
        this.vinActive = false;
      } else {
        this.vinActive = true;
        this.vinInfoList = result.vin.message;
      }
      this.isCheckVehicleImported();
      console.log(res);
    },
    //进口车验证
    async isCheckVehicleImported() {
      let params = {
        appKey: this.falseData.appKey,
        sign: this.falseData.sign,
        randomStr: this.falseData.randomStr,
        expireTime: this.falseData.expireTime,
        token: this.falseData.token || "",
        vehicleModel: this.valuationInfo.vehicleModel,
        vin: this.valuationInfo.Vin,
        brand: this.valuationInfo.brand,
        model: this.valuationInfo.model,
      };
      const res = await checkVehicleImported(params);
      this.importedModelsShow = res.data.result;
    },
    // 识别ocr
    async touchPhoto(file) {
      //  加了keep-alive 重置页面
      this.cascaderValue = "";
      this.engineBrand = "";
      this.engineModel = "";
      this.drivingTypeSelect = "";
      this.speedRatioSelect = "";
      this.gearboxSelect = "";
      this.valuationInfo = {};
      this.errorClass = 0;
      this.mileage = "";
      this.timeHidden = false;
      // this.getData();
      this.ImgList = [
        { name: "urlPic0", cname: "正前" },
        { name: "urlPic1", cname: "左前侧45度" },
        { name: "urlPic2", cname: "右前侧45度" },
        { name: "urlPic3", cname: "左侧" },
        { name: "urlPic4", cname: "右侧" },
        { name: "urlPic5", cname: "正后" },
        { name: "urlPic6", cname: "仪表盘(点火状态)" },
        { name: "urlPic7", cname: "中控" },
        { name: "urlPic8", cname: "排挡杆" },
        { name: "urlPic9", cname: "前轮" },
        { name: "urlPic10", cname: "中轮" },
        { name: "urlPic11", cname: "后轮" },
        { name: "urlPic12", cname: "发动机" },
        { name: "urlPic13", cname: "变速器" },
        { name: "urlPic14", cname: "驱动桥" },
        { name: "urlPic15", cname: "车辆铭牌" },
        { name: "urlPic16", cname: "登记证书" },
        { name: "urlPic17", cname: "其他" },
      ];

      this.imgNumber = 0; //默认0个
      //------
      var that = this; //改变this指向
      var a = 3;
      const param = new FormData();
      param.append("image", file.file);
      param.append("appKey", this.falseData.appKey);
      param.append("sign", this.falseData.sign);
      param.append("randomStr", this.falseData.randomStr);
      param.append("expireTime", this.falseData.expireTime);
      param.append("token", this.falseData.token || "");
      if (this.ocr_actives) {
        var { data: res } = await ocrConsumer(param);
      } else {
        if (this.ocr_key) {
          param.append("type", "2");
        }
        if (this.ocr_online) {
          param.append("type", "3");
        }
        if (this.ocr_qualified) {
          param.append("type", "8");
        }
        var { data: res } = await uploadImage(param);
      }
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }
      var city = res.data.result.locale.split(" ");
      var options = this.options; //获取省市区
      // console.log(options);
      options.forEach((item) => {
        if (item.label === city[0]) {
          item.children.forEach((ite) => {
            if (ite.label === city[1]) {
              ite.children.forEach((i) => {
                if (i.label === city[2]) {
                  this.cascaderValue = i.value;
                }
              });
            }
          });
        }
      });
      this.valuationInfo = res.data.result; //赋值
      let valuationInfo = this.valuationInfo;
      var obj = {
        vehicleModel: valuationInfo.vehicleModel,
        registerDate: valuationInfo.RegisterDate,
        local: valuationInfo.locale,
        appKey: this.falseData.appKey,
        sign: this.falseData.sign,
        randomStr: this.falseData.randomStr,
        expireTime: this.falseData.expireTime,
        token: this.falseData.token || "",
      };
      const { data: re } = await getVehicleOption(obj);
      let key_s = this.tips(re.code);
      if (!key_s) {
        return;
      }
      // 驱动形式
      this.drivingTypeColumns = res.data.result.drivingType;
      // 速比
      this.speedRatioColumns = res.data.result.speedRatio;
      // 变速器
      this.gearboxColumns = res.data.result.gearbox;
      // 发动机 改格式
      let arr = [];
      if (re.data.result.content) {
        this.isNewEnergy = re.data.result.content.isNewEnergy;
      }
      if (re.data.result.content.engineList) {
        var i = [];
        re.data.result.content.engineList.forEach((item) => {
          i = [];
          item.models.forEach((ite) => {
            i.push({
              text: ite.value,
            });
          });
          arr.push({
            text: item.brand,
            children: i,
          });
        });
        console.log(i);
      }
      if (arr.length == 1) {
        if (arr[0].children.length == 1) {
          this.engineBrand = arr[0].text;
          this.engineModel = arr[0].children[0].text;
        }
      }
      if (re.data.result.content.options) {
        let list = [];
        console.log(re.data.result.content.options);
        re.data.result.content.options.forEach((ite) => {
          if (ite.options.length == 1) {
            ite.value = ite.options[0].value;
          }
          ite.options.forEach((item) => {
            list.push({ text: item.value });
            ite.option = list;
          });
        });
        this.optionsList = re.data.result.content.options;
      }
      this.engineNameColumns = arr;
      this.readOnly = false; //关闭只读
      // Vin验证
      await this.checkVehicleParams();
      time(res.data.result.RegisterDate);
      function time(date) {
        date = date.substring(0, 19);
        // console.log(date);
        var timestamp = new Date(date).getTime();
        // 根据毫秒数构建 Date 对象
        var date = new Date(timestamp);
        // 格式化日期
        var dateTime = date.toLocaleString();
        if (dateTime == "Invalid Date") {
          // console.log("时间不对");
          that.timeHidden = true;
        }
      }
      // console.log(res.data.result);
      if (res.data.result.isImported) {
        this.importedModelsShow = true;
        // console.log(this.importedModelsShow);
      } else {
        // 开启 三秒倒计时
        this.promptHidden = true;
        this.isActive = true;
        // 定时器 三秒后关闭
        // console.log("111");
        this.promptTimer = setInterval(function () {
          a--;
          // console.log(a);
          if (a === -1) {
            clearInterval(that.promptTimer);
            that.promptHidden = false;
            that.isActive = false;
            return;
          }
          that.promptTitle = `确认(${a})`;
        }, 1000);
      }
    },
    // 输入车辆型号
    vehicleModelBlur: debounce(async function () {
      // 发动机清空
      this.engineBrand = "";
      this.engineModel = "";
      this.drivingTypeSelect = "";
      this.speedRatioSelect = "";
      this.gearboxSelect = "";

      let valuationInfo = this.valuationInfo;
      var obj = {
        vehicleModel: valuationInfo.vehicleModel,
        registerDate: valuationInfo.RegisterDate,
        local: valuationInfo.locale,
        appKey: this.falseData.appKey,
        sign: this.falseData.sign,
        randomStr: this.falseData.randomStr,
        expireTime: this.falseData.expireTime,
        token: this.falseData.token || "",
      };
      // console.log(res.data.result);
      // 显示隐藏
      // this.valuationInfo.isView = res.data.result.isView;

      const { data: re } = await getVehicleOption(obj);
      let key_s = this.tips(re.code);
      if (!key_s) {
        return;
      }
      if (re.data.result.resultStates !== 0) {
        Toast(re.data.result.message);
        return;
      }
      let arr = [];
      if (re.data.result.content) {
        this.isNewEnergy = re.data.result.content.isNewEnergy;
      }
      if (re.data.result.content.engineList) {
        var i = [];
        re.data.result.content.engineList.forEach((item) => {
          i = [];
          item.models.forEach((ite) => {
            i.push({
              text: ite.value,
            });
          });
          arr.push({
            text: item.brand,
            children: i,
          });
        });
      }
      if (arr.length == 1) {
        if (arr[0].children.length == 1) {
          this.engineBrand = arr[0].text;
          this.engineModel = arr[0].children[0].text;
        }
      }

      if (re.data.result.content.options) {
        let list = [];
        re.data.result.content.options.forEach((ite) => {
          if (ite.options.length == 1) {
            ite.value = ite.options[0].value;
          }
          ite.options.forEach((item) => {
            list.push({ text: item.value });
            ite.option = list;
          });
        });

        this.optionsList = re.data.result.content.options;
      }
      this.engineNameColumns = arr;
      await this.checkVehicleParams();
      // 可以获取接口
    }, 500),
    // 点击注册日期
    RegisterDateClick() {
      let readOnly = this.readOnly;
      if (readOnly) return;
      this.RegisterDateHidden = true;
    },
    // 注册日期的取消按钮
    RegisterCancel() {
      this.RegisterDateHidden = false;
    },
    // 注册日期的确定按钮
    RegisterConfirm(event) {
      // console.log(event);
      // console.log(formatDateYMM(event));
      this.valuationInfo.RegisterDate = formatDateYMM(event);
      this.RegisterDateHidden = false;
      this.timeHidden = false;
      this.vehicleModelBlur(); //获取配置选项
    },
    // 点击发证日期
    IssueDateClick() {
      let readOnly = this.readOnly;
      if (readOnly) return;
      this.IssueDateHidden = true;
    },
    // 点击使用性质
    UseCharacterDateClick() {
      let readOnly = this.readOnly;
      if (readOnly) return;
      this.UseCharacterHiddens = true;
    },
    //发证日期的取消按钮
    IssueCancel() {
      this.IssueDateHidden = false;
    },
    // 发证日期的确认按钮
    IssueConfirm(event) {
      // console.log(formatDateYMM(event));
      this.valuationInfo.IssueDate = formatDateYMM(event);
      this.IssueDateHidden = false;
    },
    // 点击 车辆注册地址
    changeRegin() {
      // console.log(this.readOnly);
      let readOnly = this.readOnly;
      if (readOnly) return;
      this.reginHidden = true;
    },
    // 省市区 选择完成后
    onFinish(event) {
      // console.log(event.selectedOptions);
      // this.valuationInfo.locale = event.selectedOptions
      //   .map((option) => option.name)
      //   .join(" ");
      let citys = [];
      event.selectedOptions.forEach((item) => {
        // console.log(item.label);
        citys.push(item.label);
      });
      // console.log(citys.join(" "));
      // this.cascaderValue = citys.split('').join(' ');
      this.valuationInfo.locale = citys.join(" ");
      this.vehicleModelBlur(); //获取配置选项
      this.reginHidden = false;
    },
    // 输入完成行驶里程
    mileEnd() {
      let t1 = /^[0-9]+(\.[0-9]{1,2})?$/;
      console.log(t1.test(this.mileage));
      // this.mileage = this.mileage.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, "$1");
    },
    // 点击发动机型号
    engineNameClick() {
      //       engineBrand: "东风康明斯"
      // engineModel: "ISDe245 40"
      this.engineNameHidden = true;
    },
    // 发动机型号确定按钮
    engineNameConfirm(event) {
      // console.log(event);

      this.engineBrand = event[0];
      this.engineModel = event[1];
      this.engineNameHidden = false;
    },
    // 点击驱动形式
    drivingTypeClick() {
      this.drivingTypeHidden = true;
    },
    // 驱动形式确认按钮
    drivingTypConfirm(event) {
      console.log(event);
      // this.drivingTypeSelect = event;
      // this.drivingTypeHidden = false;
      let list = this.optionsList;
      list.forEach((item) => {
        console.log(item);
        item.option.forEach((ite) => {
          if (ite.text == event.text) {
            console.log(ite, item);
            item.value = ite.text;
            item.select = false;
          }
        });
      });
      this.optionsList = list;
      console.log(list);
    },
    // 点击速比
    speedRatioClick() {
      this.speedRatioHidden = true;
    },
    // 速比确定按钮
    speedRatioConfirm(event) {
      // console.log(event);
      this.speedRatioSelect = event;
      this.speedRatioHidden = false;
    },
    // 点击变速器
    gearboxClick() {
      this.gearboxHidden = true;
    },
    // 变速器确认按钮
    gearboxConfirm(event) {
      // console.log(event);
      this.gearboxSelect = event;
      this.gearboxHidden = false;
    },
    // 点击添加图片按钮
    addCarImageClick() {
      let imgNumber = this.imgNumber;
      imgNumber = imgNumber + 6;
      this.imgNumber = imgNumber;
    },
    // 上传图片
    async additionalImage(file, detail) {
      // console.log(file.file);
      const param = new FormData();
      param.append("image", file.file);
      param.append("appKey", this.falseData.appKey);
      param.append("sign", this.falseData.sign);
      param.append("randomStr", this.falseData.randomStr);
      param.append("expireTime", this.falseData.expireTime);
      param.append("token", this.falseData.token || "");
      const { data: res } = await upPicture(param);
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }

      var ImgList = this.ImgList;
      ImgList.forEach((item, index) => {
        if (item.name == detail.name) {
          item.imgUrl = res.data.imgUrl;
        }
        this.$set(this.ImgList, index, item);
      });
      this.ImgList = ImgList;
    },
    // 删除图片
    imageDelete(data, index) {
      this.ImgList[index].imgUrl = "";
      this.$set(this.ImgList, index, this.ImgList[index]);
    },
    // 点击下一步
    async nextStepClick() {
      let t2 = /^[0-9]+(\.[0-9]{1,2})?$/;
      if (!t2.test(this.mileage)) {
        console.log(124);
        Dialog.alert({
          title: "提示",
          message: "行驶里程仅可输入最多两位小数的数字",
          confirmButtonColor: "#507496",
        }).then(() => {});
        this.errorClass = 0;
        return;
      }
      // console.log(this.valuationInfo.locale);
      // console.log(this.valuationInfo);
      // 校验行驶证
      // if (JSON.stringify(this.valuationInfo) == "{}") {
      //   Dialog.alert({
      //     title: "提示",
      //     message: "请您上传行驶证",
      //     confirmButtonColor: "#507496",
      //   }).then(() => {});
      //   return;
      // }

      // 车牌号码为空
      if (!this.valuationInfo.PlateNo) {
        Dialog.alert({
          title: "提示",
          message: "请您填写车牌号码",
          confirmButtonColor: "#507496",
        }).then(() => {});
        this.errorClass = 0;
        return;
      }
      // 车辆类型
      if (!this.valuationInfo.VehicleType) {
        Dialog.alert({
          title: "提示",
          message: "请您填写车辆类型",
          confirmButtonColor: "#507496",
        }).then(() => {});
        this.errorClass = 0;

        return;
      }
      // 品牌车型为空
      if (!this.valuationInfo.vehicleModel) {
        Dialog.alert({
          title: "提示",
          message: "请您填写车辆型号",
          confirmButtonColor: "#507496",
        }).then(() => {});
        this.errorClass = 0;

        return;
      }
      // 车辆识别代号为空
      if (!this.valuationInfo.Vin) {
        Dialog.alert({
          title: "提示",
          message: "请您填写车辆识别代号",
          confirmButtonColor: "#507496",
        }).then(() => {});
        this.errorClass = 0;

        return;
      }
      var time = /^(\d{4})-(\d{2})-(\d{2})$/;
      // 注册日期
      if (this.valuationInfo.RegisterDate == "") {
        Dialog.alert({
          title: "提示",
          message: "请您填写注册日期",
          confirmButtonColor: "#507496",
        }).then(() => {});
        return;
      }
      if (!time.test(this.valuationInfo.RegisterDate)) {
        Dialog.alert({
          title: "提示",
          message: "请确认注册日期",
          confirmButtonColor: "#507496",
        }).then(() => {});
        return;
      }

      // 发证日期
      // if (this.valuationInfo.IssueDate == "") {
      //   Dialog.alert({
      //     title: "提示",
      //     message: "请您填写发证日期",
      //     confirmButtonColor: "#507496",
      //   }).then(() => {});
      //   return;
      // }
      if (!time.test(this.valuationInfo.IssueDate)) {
        Dialog.alert({
          title: "提示",
          message: "请确认发证日期",
          confirmButtonColor: "#507496",
        }).then(() => {});
        return;
      }
      // 检验行驶里程
      if (!this.mileage) {
        Dialog.alert({
          title: "提示",
          message: "请您填写行驶里程",
          confirmButtonColor: "#507496",
        }).then(() => {});
        this.errorClass = 0;
        return;
      }
      let t1 = /^[0-9]+(\.[0-9]{1,2})?$/;
      if (!t1.test(this.mileage)) {
        console.log(124);
        Dialog.alert({
          title: "提示",
          message: "行驶里程为纯数字或者小数点保留两位",
          confirmButtonColor: "#507496",
        }).then(() => {});
        this.errorClass = 0;
        return;
      }
      if (!this.valuationInfo.UseCharacter) {
        Dialog.alert({
          title: "提示",
          message: "请选择使用性质",
          confirmButtonColor: "#507496",
        }).then(() => {});
        return;
      }
      // 车辆注册地址
      if (!this.valuationInfo.locale) {
        // console.log(123);
        Dialog.alert({
          title: "提示",
          message: "请您选择车辆注册地址",
          confirmButtonColor: "#507496",
        }).then(() => {});
        this.errorClass = 0;
        return;
      }
      // 如果用户上传了图片，过滤出来
      let obj = {};
      var filterList = this.ImgList.filter((item) => {
        return item.imgUrl;
      });
      //如果用户填加照片之后
      if (filterList.length != 0) {
        filterList.forEach((item) => {
          obj[item.name] = item.imgUrl;
        });
        // console.log(obj);
        this.valuationInfo.charts = {
          ...obj,
        };
        // console.log(this.valuationInfo);
      }
      this.valuationInfo.engineBrand = this.engineBrand;
      this.valuationInfo.engineModel = this.engineModel;
      this.valuationInfo.drivingTypeSelect = this.drivingTypeSelect;
      this.valuationInfo.speedRatioSelect = this.speedRatioSelect;
      this.valuationInfo.gearboxSelect = this.gearboxSelect;
      this.valuationInfo = Object.assign(this.valuationInfo, this.falseData);
      this.valuationInfo.mileage = this.mileage;
      if (this.optionsList) {
        let status = true;
        let obj = {};
        this.optionsList.forEach((item) => {
          if (!item.value) {
            status = false;
          }
          obj[item.paramName] = item.value;
        });
        if (!status) {
          Dialog.alert({
            title: "提示",
            message: "请填写配置项内容",
            confirmButtonColor: "#507496",
          }).then(() => {});
          return;
        }
        this.valuationInfo = Object.assign(this.valuationInfo, obj);
      }
      await this.checkTrailers();
      const { data: res } = await checkVehiclePreSale({
        vehicleModel: this.valuationInfo.vehicleModel,
        engineModel: this.valuationInfo.engineModel,
        registerDate: this.valuationInfo.RegisterDate,
        issueDate: this.valuationInfo.IssueDate,
        mileage: this.valuationInfo.mileage,
        appKey: this.falseData.appKey,
        sign: this.falseData.sign,
        randomStr: this.falseData.randomStr,
        expireTime: this.falseData.expireTime,
        token: this.falseData.token || "",
      });
      this.valuationInfo.isPreSale = res.data.result.content.preSale;
      localStorage.setItem("valuationInfo", JSON.stringify(this.valuationInfo));
      if (res.data.result.content.preSale) {
        this.trailerShow = res.data.result.content.preSale;
        return;
      }
      this.$router.push("/quickValuation/v2/secondarySubmit");
      return;
    },
    onClickTrailer(k) {
      if (k == 2) {
        this.valuationInfo.isPreSale = fasle;
        localStorage.setItem(
          "valuationInfo",
          JSON.stringify(this.valuationInfo)
        );
      }
      this.$router.push("/quickValuation/v2/secondarySubmit");
      this.trailerShow = false;
    },
    async getData() {
      this.ImgList = this.pic.data.charts;
      // console.log(this.ImgList);
      this.options = city;
      let falseData = {
        appKey: this.$store.state.params.appKey,
        sign: this.$store.state.params.sign,
        randomStr: this.$store.state.params.randomStr,
        expireTime: this.$store.state.params.expireTime,
        token: this.$store.state.params.token || "",
      };
      // let falseData = {
      //   appKey: "cada417a94357a9521ebf1b7",
      //   sign: "de83da26c62673c9c1c805c5b513425f",
      //   randomStr: "12345678",
      //   expireTime: "1658303858000",
      //   token: '123125634165324651',
      // };
      this.falseData = falseData;
      // console.log(this.falseData);
      // console.log(this.$route.query);
    },
  },
  beforeCreate() {},
  created() {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://s4.cnzz.com/z_stat.php?id=1281102051&web_id=1281102051";
    // document.body.appendChild(s);
    // console.log(document.body);
    let params = {
      appKey: "f5ba80f561e0d8795f41c17b",
      sign: "a783bf0b27e78a597b69425c423f0f3e",
      randomStr: "12345678",
      expireTime: "1677754460433",
      token: "123456",
    };
    this.$route.query.token = this.$route.query.token || "";
    this.$store.commit("setParams", this.$route.query);
    // this.$store.commit("setParams", params);
  },
  beforeMount() {},
  mounted() {
    let str = unescape(
      "%3Cspan id='cnzz_stat_icon_1281102051'%3E%3C/span%3E%3Cscript src='https://s4.cnzz.com/z_stat.php%3Fid%3D1281102051' type='text/javascript'%3E%3C/script%3E"
    );
    // this.statistics = str;
    // this.$refs.home.appendChild(spanContent);
    this.getData();
    // console.log(this.$store.state.params);
    // console.log(JSON.stringify(this.$store.state.params));
  },
  watch: {
    "valuationInfo.brand": {
      immediate: true,
      deep: true,
      // 对对象的某一个属性进行深度监听
      handler(newVal, oldVal) {
        if (!newVal) return;
        if (newVal.indexOf("解放") != -1) {
          this.vehicleInfoHidden = true;
        } else {
          this.vehicleInfoHidden = false;
        }
      },
    },
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
};
</script> 

<style scoped lang="less">
#app {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  /* padding-top: 10px; */
  background: #f2f2f2;
  font-family: PingFang-SC-Bold, PingFang-SC;
  overflow: hidden;
}
/* 顶部实例 */
.instance {
  width: 100%;
  height: 50px;
  /* margin: 0 auto; */
}
/* ocr */
.ocrIdentify {
  width: 100%;
  height: 256px;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 10px;
}
.images {
  width: 184px;
  height: 138px;
}
.ocrIdentifyTop {
  width: 100%;
  height: 24px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.title {
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  box-sizing: border-box;
}

.help {
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  padding-right: 5px;
}

/* 车辆信息 */
.carInfoView {
  margin-top: 10px;
  width: 100%;
  min-height: 378px;
  background: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 10px 0;
}
.zixiSecondFlex {
  position: absolute;
  right: 40px;
  font-size: 14px;
  color: #fe4000;
}
.carInfoViewLi {
  width: 100%;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  align-items: center;
  position: relative;
}
.carInfoViewLis {
  display: block;
  min-height: 80px;
}
.carInfoViewLis > div {
  display: flex;
  align-items: center;
  height: 40px;
}
.carInfoViewLiss {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
}
.newVals {
  height: 40px;
  height: 25px !important;
  background: #fff1f1;
  border-radius: 5px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fa4444;
}
.newVals > img {
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.newVals > div {
  margin-right: 100px;
}
.carInfoViewLiText {
  width: 114px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.carInfoViewLiActive {
  color: #fe4000 !important;
}
.carInfoViewLi > input,
.carInfoViewLis input,
.carInfoViewLiss > input {
  border: none;
  width: 186px;
  flex: 1;
  font-size: 14px;
  // font-weight: bold;
  color: #333333;
  margin-left: 10px;
}
input::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.basisInfoView {
  width: 100%;
  height: 104px;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 10px;
}

/* 基础信息 */
.basisInfoViewLi {
  width: 100%;
  height: 22px;
  display: flex;
  align-items: center;
}
.basisInfoViewLiText {
  min-width: 88px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-left: 10px;
}
.basisInfoViewLi > input {
  border: none;
  // flex: 1;
  width: 100%;
  font-size: 14px;
  height: 24px;
  // font-weight: bold;
  color: #333333;
  margin-left: 10px;
}
/* 车辆配置选项 */
.configurationView {
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 10px;
}
.pickerLi {
  line-height: 30px;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.pickerLi > div:nth-of-type(1) {
  width: 120px;
}
.pickerText {
  width: 200px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
/* 车辆图片 */
.carImageView {
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 10px;
}
.carImageBtn {
  width: 100%;
  height: 40px;
  background: rgba(18, 113, 255, 0.69);
  border-radius: 6px;
  border: none;
  margin-top: 20px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
}
.carImageViewFlex {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.carImageViewFlexLi {
  width: 163px;
  height: 140px;
  /* background: rgba(1, 54, 103, 0.3);
  border-radius: 10px; */
}
.carImageViewFlexLi .image {
  width: 163px;
  height: 108px;
  background: #f0f6ff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.carImageViewFlexLi .text {
  font-size: 12px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-top: 10px;
}
.remoteImage {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  z-index: 99999;
}
/* 下一步 */
.nextStep {
  border: none;
  width: 100%;
  height: 44px;
  background: #1271ff;
  border-radius: 6px;
  margin: 28px 0 22px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}
.promptView {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}
.promptTextSize {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  text-align: center;
}
.mar10 {
  margin-top: 10px;
}
.g-content {
  font-size: 16px;
}
.g-title {
  text-align: center;
  height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f3f3f3;
}
.g-brand {
  width: 100vw;
  display: flex;
  box-sizing: border-box;
  padding: 0 20px;
  margin-top: 20px;
}
.g-car {
  display: block;
}
.g-name {
  width: 60px;
}
.g-items {
  display: flex;
}

.g-select {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.g-select > div:nth-of-type(2) {
  flex: 1;
  text-align: right;
}
.g-tips {
  font-size: 14px;
  font-weight: 400;
  color: #5a6f83;
  text-indent: 20px;
  margin-top: 20px;
}
.g-btn {
  width: 250px;
  height: 44px;
  background: #1271ff;
  border-radius: 5px;
  text-align: center;
  line-height: 44px;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 20px;
}
.upload_title {
  height: 22px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.ocrImageView {
  width: 324px;
  height: 170px;
  border-radius: 5px;
  border: 1px dashed #d9d9d9;
  display: flex;
  box-sizing: border-box;
  padding: 15px;
  justify-content: space-between;
}
.ocrImageView > .van-uploader {
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__wrapper {
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__input-wrapper {
  width: 100%;
  height: 100%;
}
.ocr_ul {
}
.ocr_li {
  width: 74px;
  line-height: 26px;
  background: #f2f2f2;
  border-radius: 13px;
  text-align: center;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.ocr_active {
  background: #deedff;
  color: #0378eb;
}
.ocr_img_show {
  position: relative;
}
.clear_ocr {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 45px;
  background: url("https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation/delete_20220926.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 23px;
  text-align: center;
}
/* 解放弹窗 */
.customizationPopup {
  width: 266px;
  height: 296px;
  position: relative;
  background: url(https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/customization.png)
    no-repeat;
  background-size: 100%;
}

.jiefangButton {
  width: 150px;
  height: 36px;
  background: rgba(18, 113, 255, 0.69);
  border-radius: 6px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0%);
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.trailer {
  width: 240px;
  height: 166px;
  background: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 24px 20px;
}

.trailer > .title {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
}

.trailer > .content {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin-top: 16px;
}

.trailer > .center {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-top: 7px;
  text-align: center;
}

.trailer > .btn {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
}

.trailer > .btn > div {
  width: 92px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.trailer > .btn > .left {
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #1271ff;
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #1271ff;
}

.trailer > .btn > .right {
  background: #1271ff;
  border-radius: 6px;
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #ffffff;
}
.inp_active {
  color: #fe4000 !important;
}
.vinActiveImage {
  width: 16px;
  height: 16px;
  padding-left: 10px;
}
.vinShow {
  width: 295px;
  min-height: 100px;
  background: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 24px 20px;
}

.vinShow > .title {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
  margin-bottom: 10px;
}

.vinShow > .conent {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
}

.vinShow > .btn {
  width: 110px;
  height: 30px;
  background: #1271ff;
  border-radius: 6px;
  margin: 22px auto 0;
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
}
.iconImages {
  width: 16px;
  height: 14px;
}
</style>
